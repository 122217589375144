<template>
    <div>
		<el-table stripe border v-loading="loading" :data="dataList" >
			<el-table-column :label="translate('THIRD_USER_AUTH.ID')" prop="id" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('THIRD_USER_AUTH.CODE')" prop="code" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('THIRD_USER_AUTH.VALUE')" prop="value" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('THIRD_USER_AUTH.DESCRIPTION')" prop="description" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.STATUS')" prop="status" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<el-switch v-model="scope.row.state" active-value="ENABLE" inactive-value="DISABLE" @change="handleStatusChange(scope.row)"/>
				</template>
			</el-table-column>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.LAST_UPDATE_TIME')" prop="updateTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.OPERATION')" align="center" class-name="small-padding fixed-width" width="180" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleUpdate(scope.row)" v-permission="'BASIC_CONFIG_UPDATE'">{{translate('OPERATION.UPDATE')}}</el-button>
					<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-permission="'BASIC_CONFIG_DELETE'">{{translate('OPERATION.DELETE')}}</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

		<CustomForm :dicts="dicts"
					:on-fresh="showCustomForm"
					:isAdd="isAdd"
					:selectRow="selectRow"
					@on-close="() => showCustomForm = false" ></CustomForm>
	</div>
</template>

<script>
	import {UserApi} from '@/api';
	import CustomForm from "./CustomForm";
	export default {
		name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await UserApi.config.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleStatusChange(row) {
				let text = row.status === "DISABLE" ? "FROM.DISABLE" : "FROM.ENABLE";
				this.$confirm(this.translate('FROM.CONFIRM_OPERATION', {module: 'MODULE.THIRD_USER_LOGIN', operation: text}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.config.edit(row.id, {
						state: row.state
					});
					if (result.success) {
						this.getList();
					}
				}).catch(() => this.getList());
			},
			async handleUpdate(row) {
				this.showCustomForm = true;
				this.selectRow = row;
				this.isAdd = false;
			},
			handleDelete(row){
				this.$confirm(this.translate('FROM.CONFIRM_DELETE', {module: 'MODULE.THIRD_USER_LOGIN', id: row.id}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.config.remove(row.id);
					if (result.success) {
						this.Message.success(this.translate('FROM.DELETE_SUCCESS'));
						this.getList();
					}
				});
			},
		}
	};
</script>

<style scoped>

</style>
